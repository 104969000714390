import React from 'react'

function Home() {
    const redirectToPlayStore = () => {
        // Replace 'your-package-name' with the actual package name of your app on the Play Store
        const packageName = 'com.shahidarbarkolhapur.shahidarbar';
        window.location.href = `https://play.google.com/store/apps/details?id=${packageName}`;
      };
  return (
    <div className='container'>
        <div className='content'>
        <img src='/logo.png' className='logo'/>
            <h1>Welcome to Shahi Darbar</h1>
                <h2>Custom Thali</h2>
                <h3>Contact : 0231-2991819</h3>
                <h3>Address : Near D.Y patil hospital</h3>
                <p>We Provide Service of Custom Thali in Non Veg Cusine for Dine In and Dine Out</p>
                <button onClick={redirectToPlayStore} className='download'>Download App </button>
        </div>
    </div>
  )
}

export default Home

import React, { useState } from 'react';
import styles from './error.module.css'
import { Link } from 'react-router-dom'

class ErrorBoundary extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = { hasError: false };
   
    console.log("ero9r")
    
  }
  
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    
    this.setState({ hasError: true });

    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className={styles.container}>
          <div className={styles.content}>
              <h1>Something Went Wrong</h1>
              <p><b>Contact Us</b></p>
              <button  className={styles.button__not}><Link to="/">Go back to the homepage</Link></button>
          </div>
        </div>
      );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;

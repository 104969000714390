import React,{useState} from 'react';
import toastr from "toastr";
import "toastr/build/toastr.css";
import axios from 'axios';
import { Circles } from 'react-loader-spinner';
function UserDeletion() {
    const [data, setData] = useState({
        mobile_no:"",
        otp:""
      })
      const BASE_URL = 'https://api.shahidarbarkolhapur.in'
      const [otpSent, setotpSent] = useState(false)
      const [loading, setLoading] = useState(false)
      const handleClick = () => {
        if(otpSent){
          handleSubmit()
        }
        else{
          if(data.mobile_no){
            setLoading(true)
            const url = `${BASE_URL}/customer/user-create/`
            axios.post(url, {mobile_no:data.mobile_no})
            .then(response => {
              setotpSent(true)
              toastr.info("Otp Sent Successfully")
              setLoading(false)
            })
            .catch(error => {
              // Handle error
              toastr.error("Otp Sending Failed")
              setLoading(false)
            });
           
          }
          else{
            toastr.error('Enter Mobile no')
          }
          
        }
      }
    
      const handleSubmit = () => {
        if(data.mobile_no && data.otp){
          setLoading(true)
          const url = `${BASE_URL}/customer/user-delete/?mobile_no=${data.mobile_no}&otp=${data.otp}`
          axios.delete(url)
          .then(response => {
            setotpSent(false)
            toastr.info("User Deleted Successfully")
            setLoading(false)
          })
          .catch(error => {
            setLoading(false)
            toastr.error(error.response.data.message)
          });
         
        }
        else{
          toastr.error('Enter Mobile no')
        }
      }
      return (
        <div className='container'>
            <div className='content'>
            {loading ? <Circles type="TailSpin" color="#00BFFF" height={80} width={80} /> :
            <> 
            <h2>Delete User using Otp</h2>
                <input
                value={data.mobile_no}
                onChange={e => setData({...data,mobile_no:e.target.value})}
                className='inp'
                type='text'
                placeholder='Enter Mobile Number'
                />
                <input 
                value={data.otp}
                onChange={e => setData({...data,otp:e.target.value})}
                className='inp'
                type='text'
                placeholder='Enter Otp'
                />
                <button onClick={handleClick} className='submit'>{otpSent ? 'Submit' : 'Get Otp'}</button> </>}
            </div>
        </div>
      );
}

export default UserDeletion
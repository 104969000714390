import React from 'react';
import toastr from "toastr";
import "toastr/build/toastr.css";
import './App.css';

import { Route,Routes, BrowserRouter as Router, Outlet, useLocation } from 'react-router-dom' 
import ErrorBoundary from './ErrorBoundary';
import Home from './Home';
import UserDeletion from './UserDeletion';



function App() {
  return (
  <Router>
    <ErrorBoundary>
    <Routes>
        <Route exact path='/' element=<Home/> />
        <Route  path='/user-delete' element=<UserDeletion/> />
        <Route path='*' element={<h1>Not Found</h1>} />
        </Routes>
    </ErrorBoundary>
  </Router>
  )
}

export default App;
